export const linkResolver = (doc, langOverride) => {
  if (doc?.url?.includes("http")) return doc.url

  const docType = doc?.type ?? doc?._meta?.type
  const docUid = doc?._meta?.uid ?? doc?.uid
  const docLang = langOverride ?? doc._meta?.lang ?? doc?.lang
  let langUrl = docLang === "en-gb" ? "en" : ""

  let url
  switch (docType) {
    case "home":
      url = `/`
      break
    case "page":
      url = `/${docUid}`
      break
    case "property":
      docLang === "en-gb"
        ? (url = `holiday-listing/${docUid}`)
        : (url = `casas-para-ferias/${docUid}`)
      break
    default:
      url = `/${docUid}`
  }
  if (docUid === "home") url = `/`

  if (docLang !== process.env.GATSBY_DEFAULT_LANGUAGE) {
    url = `/${langUrl}${url}`
  }

  return url
}
