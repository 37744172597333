module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"keystoalgarve1","accessToken":"MC5ZVkNTbnhFQUFDNEEtaEI2.77-9azcU77-9De-_vTjvv70G77-9GO-_ve-_vTPvv71V77-977-977-977-977-977-9Su-_vVPvv70X77-9Q--_ve-_vQ","customTypesApiToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoibWFjaGluZTJtYWNoaW5lIiwiZGJpZCI6ImtleXN0b2FsZ2FydmUxLTdlYzY0ZWFlLTQ4YjAtNGRhZi04YWY1LWIxZGZiMjUyMmQ0M180IiwiZGF0ZSI6MTY4ODkxMzA2MCwiZG9tYWluIjoia2V5c3RvYWxnYXJ2ZTEiLCJpYXQiOjE2ODg5MTMwNjB9.NrQ5xxM9H-p07pEuIl2KyT0q9Nt4M3-kTIAI6JSrG4s"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
