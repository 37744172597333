import React, { useState, useEffect } from "react"
import { PropTypes } from "prop-types"
// import {scrollToElement} from 'scroll-to-element'
// import { motion } from "framer-motion"
import "../../node_modules/uikit/dist/js/uikit.js"
import Header from "./header"
import CookieBar from "./slices/CookieBar"
import Footer from "./footer"

import "./layout.css"
// import Swiper from './swiper'

const Layout = ({
  pathname,
  navigation,
  children,
  noHeader,
  noFooter,
  readingProgress,
}) => {
  const [hasMounted, setHasMounted] = useState(false)

  useEffect(() => {
    setHasMounted(true)

    // if (hasMounted && typeof window !== "undefined") {
    //   if (window.location.hash) {
    //     scrollToElement(window.location.hash, {
    //       offset: 0,
    //       duration: 400,
    //       behavior: 'smooth'
    //     })
    //   }
    // }
  }, [hasMounted])

  if (!hasMounted) {
    return null
  }

  // console.log('[Layout.js] pathname', pathname)
  const pathname_name = pathname ?? ""

  return (
    <>
      {!noHeader && (
        <Header
          pathname={pathname_name}
          readingProgress={readingProgress}
          navigation={navigation}
        />
      )}

      {/* <motion.main
          initial={{ opacity: 0, x: 0 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 0 }}
          id="main"
          transition={{
            type: "spring",
            mass: 0.35,
            stiffness: 75,
            duration: 1
          }}
        > */}
      <main id="main">{children}</main>
      {/* </motion.main> */}

      {/* <main id="main">{children}</main> */}

      {/* <CookieBar pathname={pathname_name} /> */}

      {!noFooter && <Footer navigation={navigation} pathname={pathname_name} />}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  pathname: PropTypes.string,
  noHeader: PropTypes.bool,
  noFooter: PropTypes.bool,
  readingProgress: PropTypes.bool,
}

export default Layout
