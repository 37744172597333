import * as React from "react"
import { PrismicPreviewProvider } from "gatsby-plugin-prismic-previews"

import { linkResolver } from "./src/utils/linkResolver"
import PageTemplate from "./src/templates/page"

export const wrapRootElement = props => {
  return (
    <PrismicPreviewProvider
      repositoryConfigs={[
        {
          repositoryName: process.env.GATSBY_PRISMIC_REPO,
          linkResolver,
          componentResolver: {
            page: PageTemplate,
          },
        },
      ]}
    >
      <div
        className={props.path === "/" ? "load_effect loaded" : "load_effect"}
        id="load_effect"
      >
        {props.element}
      </div>
    </PrismicPreviewProvider>
  )
}

// https://stackoverflow.com/questions/58225352/need-to-refresh-previously-vistied-gatsby-site-to-view-changes
// trigger an immediate page refresh when an update is found
// export const onServiceWorkerUpdateReady = () => window.location.reload();
export const onServiceWorkerUpdateReady = () => window.location.reload(true)
// export const onServiceWorkerUpdateFound = () => window.location.reload(true);
