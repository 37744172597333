import React, { useMemo, useEffect, useState } from "react"
import { get } from "lodash"
import { StaticQuery, graphql } from "gatsby"

import { linkResolver } from "../../utils/linkResolver"

import PropertyCard from "./PropertyCard"

const PropertyList = ({ data, lang }) => {
  console.log("PropertyList data", data)

  const title = useMemo(() => get(data, "primary.title1.text"), [data])
  const cards = useMemo(() => get(data, "items"), [data])

  return (
    <section className="section">
      {title && (
        <header className="row section__header">
          <div className="col col-6">
            <h3>{title}</h3>
          </div>
        </header>
      )}

      <div className="section__container section__container-rm-margin">
        <div className="row">
          <div className="col col-12">
            <div className="property_card__container_list">
              <div className="property_card__container_list__inner">
                {cards &&
                  cards?.map((card, index) => (
                    <PropertyCard
                      lang={lang}
                      data={card?.property_card.document}
                      index={index}
                      key={index}
                    />
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PropertyList

export const PropertyListQuery = graphql`
  fragment PropertyList on PrismicPageDataBodyPropertiesList {
    id
    slice_type
    slice_label
    primary {
      title1 {
        text
      }
    }
    items {
      property_card {
        document {
          ... on PrismicProperty {
            ...PropertyCard
          }
        }
        uid
        type
        lang
      }
    }
  }
`
