import React, { useMemo } from "react"
import { get } from "lodash"
import { graphql } from "gatsby"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import SEO from "../components/seo"
import Layout from "../components/layout"
import Hero from "../components/slices/Hero"
import Highlights from "../components/slices/Highlights"
import PropertyList from "../components/slices/PropertyList"

// Display the title, date, and content of the Post
const Page = ({ page }) => {
  console.log("[page.js] page", page)

  const lang = page.lang
  const data = page.data

  const renderSlice = (slice, index) => {
    console.log("[page.js] slice", slice)

    if (slice && slice.slice_type === "hero") {
      return <Hero key={index} data={slice} index={index} lang={lang} />
    }

    if (slice && slice.slice_type === "highlights") {
      return <Highlights key={index} data={slice} index={index} lang={lang} />
    }

    if (slice && slice.slice_type === "properties_list") {
      return <PropertyList key={index} data={slice} index={index} lang={lang} />
    }

    return []
  }

  if (!data) return null

  return (
    <section>
      <div className="containerGlobal">
        {data && data?.body?.map((slice, index) => renderSlice(slice, index))}
      </div>
    </section>
  )
}

const PageTemplate = props => {
  if (!props) return null

  const doc = props.data.prismicPage
  const navigation = props.data.prismicNavigation

  if (!doc) return null

  const seoTitle = useMemo(() => get(doc, "data.seo_title.text"), [doc])
  const seoText = useMemo(() => get(doc, "data.seo_meta.text"), [doc])
  const seoImage = useMemo(() => get(doc, "data.seo_image.url"), [doc])

  const meta = {
    uid: doc.uid,
    type: doc.type,
    lang: doc.lang,
    alternate_languages: doc.alternate_languages,
  }

  return (
    <Layout pathname={props.path} meta={meta} navigation={navigation}>
      <SEO title={seoTitle} description={seoText} image={seoImage} />
      <Page page={doc} />
    </Layout>
  )
}

export default withPrismicPreview(PageTemplate)

export const query = graphql`
  query pageQuery($lang: String, $uid: String) {
    prismicPage(uid: { eq: $uid }, lang: { eq: $lang }) {
      uid
      lang
      alternate_languages {
        uid
        lang
        url
      }
      data {
        seo_title {
          text
        }
        seo_meta {
          text
        }
        seo_image {
          url
        }
        title {
          richText
        }
        body {
          ...Hero
          ...Highlights
          ...PropertyList
        }
      }
    }
    prismicNavigation(lang: { eq: $lang }) {
      ...Navigation
    }
  }
`
