import React, { useMemo, useEffect, useState } from "react"

import { get } from "lodash"
import { useStaticQuery } from "gatsby"
import { graphql } from "gatsby"
import { linkResolver } from "../../utils/linkResolver"
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules"
import { Swiper, SwiperSlide } from "swiper/react"
import { featureResolver } from "../../utils/featureResolver"
import StarImg from "../../images/star_kta.svg"

import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import { ImgixImage } from "../ImigixImage"

const PropertyCard = ({ data, index, lang }) => {
  const propertyData = data.data

  if (!propertyData || propertyData.visible === false) return null

  // Main info
  const title = useMemo(() => get(propertyData, "title.text"), [propertyData])
  const available = useMemo(() => get(propertyData, "active"), [propertyData])
  const location = useMemo(
    () => get(propertyData, "location.document.data.title.text"),
    [propertyData],
  )
  const property_type = useMemo(
    () => get(propertyData, "property_type.document.data.title.text"),
    [propertyData],
  )
  const min_price_night = useMemo(
    () => get(propertyData, "min_price_night"),
    [propertyData],
  )
  const maximum_of_guests = useMemo(
    () => get(propertyData, "maximum_of_guests"),
    [propertyData],
  )
  const number_of_bedrooms = useMemo(
    () => get(propertyData, "number_of_bedrooms"),
    [propertyData],
  )
  const number_of_wc = useMemo(
    () => get(propertyData, "number_of_wc"),
    [propertyData],
  )
  const photos = useMemo(() => get(propertyData, "photos"), [propertyData])
  const main_features = useMemo(
    () => get(propertyData, "main_features"),
    [propertyData],
  )
  const link = linkResolver(data)

  // Render photos
  const renderPhoto = (photo, index) => {
    const image = get(photo, "photo")
    const alt = get(photo, "photo.alt")

    return (
      <SwiperSlide key={index}>
        <ImgixImage
          src={image?.url}
          alt={alt ? alt + " - Keys to Algarve" : "Keys to Algarve"}
          desktop={25}
          tablet={33}
          mobile={100}
          loading="eager"
        />
        <p>{image?.alt}</p>
      </SwiperSlide>
    )
  }

  // Render main features
  const renderFeature = (feature, index) => {
    console.log("feature", feature)

    const main_feature_type = get(feature, "main_feature_type")
    const feature_name = featureResolver(main_feature_type, lang, "text")

    return <li key={index}>{feature_name}</li>
  }

  return (
    <a href={link} className="property_card">
      <div className="property_card__image">
        <div className="property_card__image__top_info">
          {available ? null : (
            <div className="tag_feature tag_feature-error h7">
              {lang === "pt-pt" ? "Indesponível" : "Unavailable"}
            </div>
          )}
          {/* <div className="property_card__image__top_info__review h7">
            <img src={StarImg} />4.2
          </div> */}
        </div>
        <div className="property_card__image__swiper">
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            spaceBetween={0}
            slidesPerView={1}
            navigation={true}
            loop={true}
            pagination={{ dynamicBullets: true, clickable: true }}
            onSwiper={swiper => console.log(swiper)}
            onSlideChange={() => console.log("slide change")}
          >
            {photos.map((photo, index) => renderPhoto(photo, index))}
          </Swiper>
        </div>
      </div>

      <div className="property_card__info">
        <div className="property_card__info__top">
          <h6 className="h7">
            {location} &#x2022; {property_type}
          </h6>
          <h5>{title}</h5>

          <div className="property_card__info__details p-small">
            {maximum_of_guests} {lang === "pt-pt" ? "pessoas" : "people"}{" "}
            &nbsp;&#x2022;&nbsp;
            {number_of_bedrooms}&nbsp;
            {number_of_bedrooms > 1
              ? lang === "pt-pt"
                ? "quartos"
                : "bedrooms"
              : lang === "pt-pt"
              ? "quarto"
              : "bedroom"}
            &nbsp;&#x2022;&nbsp;
            {number_of_wc} wc
            {/* <br /> */}
            <ul className="withdots">
              {main_features &&
                main_features.map((feature, index) =>
                  renderFeature(feature, index),
                )}
            </ul>
          </div>
        </div>

        <div className="property_card__info__bottom">
          <h6 className="h7">{lang === "en" ? "Starting from" : "Desde"}</h6>
          <div className="property_card__info__price">
            <span className="h5">{min_price_night}€</span>
            <span className="p-small">
              /{lang === "en" ? "night" : "noite"}
            </span>
          </div>
        </div>
      </div>
    </a>
  )
}

export default PropertyCard

export const PropertyCardQuery = graphql`
  fragment PropertyCard on PrismicProperty {
    id
    url
    uid
    type
    data {
      visible
      title {
        text
      }
      active
      min_price_night
      maximum_of_guests
      number_of_bedrooms
      number_of_wc
      photos {
        photo {
          alt
          copyright
          url
        }
      }
      main_features {
        main_feature_type
        main_feature_title {
          text
        }
      }
      location {
        document {
          ... on PrismicLocation {
            data {
              title {
                text
              }
            }
          }
        }
      }
      property_type {
        document {
          ... on PrismicPropertyType {
            data {
              title {
                text
              }
            }
          }
        }
      }
    }
  }
`
