import React, { useState, useMemo, useEffect } from "react"
// import { Router, Link } from "@reach/router"
import { useStaticQuery } from "gatsby"
import { get } from "lodash"
import { graphql } from "gatsby"
import { linkResolver } from "../utils/linkResolver"
import LogoImg from "../images/logo.svg"

const Header = ({ pathname, navigation }) => {
  // Set states
  const [isOpen, setIsOpen] = useState(true)
  const [isOpenClass, setIsOpenClass] = useState("")

  useEffect(() => {
    // MOBILE HEIGHT

    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`)

    // We listen to the resize event
    window.addEventListener("resize", () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty("--vh", `${vh}px`)
    })
  })

  // Set Data
  const lang = useMemo(() => get(navigation, "lang"), [navigation])
  const items = useMemo(() => get(navigation, "data.body") || [], [navigation])

  const toggleClassHandler = () => {
    if (isOpen === true) {
      setIsOpenClass(" open")
    } else {
      setIsOpenClass("")
    }
  }

  const toggleMenuHandler = () => {
    setIsOpen(!isOpen)
    toggleClassHandler()
  }

  const renderItem = (item, index, isMobile) => {
    const text = get(item, "primary.title.text")
    const linkUrl = linkResolver(item.primary.link, lang)

    let active_class = ""
    if (linkUrl + "/" === pathname) {
      active_class = " active"
    }

    return (
      <li key={index} className={isMobile ? "h5" : ""}>
        <a href={linkUrl} className={"btn-secondary-grey" + active_class}>
          {text}
        </a>
      </li>
    )
  }

  return (
    <header className={"main_header" + isOpenClass}>
      <nav className="main_nav">
        <div className="main_nav__logo">
          <div className="main_nav__logo__container">
            <a href="/">
              <img src={LogoImg} />
            </a>
          </div>
        </div>

        <ul className="main_nav__ul_right">
          {items && items?.map((item, index) => renderItem(item, index, false))}
        </ul>
      </nav>

      <nav className="mobile_nav">
        <div className="main_nav__logo">
          <div className="main_nav__logo__container">
            <a href="#">
              <img src={LogoImg} />
            </a>
          </div>
        </div>

        <ul className="mobile_nav__ul_burger" onClick={toggleMenuHandler}>
          <li></li>
          <li></li>
        </ul>

        <ul className="mobile_nav__links mobile_nav__content">
          {items && items?.map((item, index) => renderItem(item, index, true))}
        </ul>

        {/* <div className="mobile_nav__lang mobile_nav__content">
          <select name="language" id="language_nav">
            <option value="english">English</option>
            <option value="postuguese">Português</option>
          </select>
        </div> */}
      </nav>
    </header>
  )
}

export default Header
