export const featureResolver = (feature, lang, type) => {
  
  if (type=== "text") {
    switch (feature) {
      case 'Swimming pool' :
        if(lang === 'en') {
          return 'Swimming pool'
        } else {
          return 'Piscina'
        }
      case 'Air conditioning' :
        if(lang === 'en') {
          return 'Air conditioning'
        } else {
          return 'Ar condicionado'
        }
      case 'Garden' :
        if(lang === 'en') {
          return 'Garden'
        } else {
          return 'Jardim'
        }
      case 'Streaming services' :
        if(lang === 'en') {
          return 'Streaming services'
        } else {
          return 'Serviços de streaming'
        }
      case 'Coffee Machine' :
        if(lang === 'en') {
          return 'Coffee Machine'
        } else {
          return 'Máquina de café'
        }
      case 'Available Parking' :
        if(lang === 'en') {
          return 'Available Parking'
        } else {
          return 'Estacionamento disponível'
        }
      case 'Beachfront' :
        if(lang === 'en') {
          return 'Beachfront'
        } else {
          return 'Frente-mar'
        }
      case 'Free Wifi' :
        if(lang === 'en') {
          return 'Free Wifi'
        } else {
          return 'Wifi Grátis'
        }
      case 'Jacuzzi' :
        if(lang === 'en') {
          return 'Jacuzzi'
        } else {
          return 'Jacuzzi'
        }
      case 'Golf' :
        if(lang === 'en') {
          return 'Golf'
        } else {
          return 'Golfe'
        }
      case 'Garage' :
        if(lang === 'en') {
          return 'Garage'
        } else {
          return 'Garagem'
        }
    }
  }

  return null
};