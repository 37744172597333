import React, { useEffect } from "react"
import { useInView } from "react-intersection-observer"

export const ImgixImage = ({
  src,
  alt = "",
  className = "",
  width = 500,
  height,
  loading = "lazy",
  desktop = 50,
  tablet = 50,
  mobile = 50,
  crop = false,
  index,
}) => {
  const { ref, inView, entry } = useInView({
    /* Optional options */
    triggerOnce: false,
    threshold: 0.5,
    trackVisibility: true,
    delay: 100,
  })

  const srcSetArray = new Array(20).fill("").map((_, index) => {
    return `${src}&w=${(index + 1) * 100}&auto=format ${(index + 1) * 100}w`
  })
  const srcSet = srcSetArray.join(", ")
  const sizes = `(min-width: 1024px) ${desktop}vw, (min-width: 768px) ${tablet}vw, ${mobile}vw`

  const targetSrc =
    src +
    (height ? "&h=" + height : "") +
    (width ? "&w=" + width : "") +
    (crop ? "&fit=crop" : "")

  useEffect(() => {
    if (inView) {
      const image = entry.target
      const imgSrc = image.dataset.src
      const imgSrcSet = image.dataset.srcset
      image.src = imgSrc
      image.srcset = imgSrcSet
      // image.removeAttribute("data-src");
      // image.removeAttribute("data-srcset");
    }
  }, [inView])

  return (
    <img
      ref={ref}
      src={loading === "eager" ? targetSrc : src + "&w=20&auto=format"}
      srcSet={loading === "eager" ? srcSet : undefined}
      data-src={targetSrc}
      data-srcset={srcSet}
      sizes={sizes}
      alt={alt}
      className={className}
    />
  )
}
