import React, { useMemo, useEffect, useState } from "react"
import { graphql } from "gatsby"
import { RichText } from "prismic-reactjs"
import { get } from "lodash"
import { linkResolver } from "../../utils/linkResolver"
import { PrismicRichText } from "@prismicio/react"

const Hero = ({ data }) => {
  console.log("[Hero.js] data", data)

  const text = useMemo(() => get(data, "primary.text.richText"), [data])
  const image = useMemo(() => get(data, "primary.background"), [data])

  return (
    <section className="home_hero">
      <div className="home_hero__box">
        <div className="home_hero__image">
          <img src={image?.url} alt="alt" />
        </div>

        <div className="home_hero__container">
          <div className="row">
            <div className="col col-12">
              <PrismicRichText field={text} linkResolver={linkResolver} />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero

export const HeroQuery = graphql`
  fragment Hero on PrismicPageDataBodyHero {
    id
    slice_type
    slice_label
    primary {
      text {
        richText
      }
      background {
        url
        alt
        copyright
      }
    }
  }
`
