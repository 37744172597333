import React, { useMemo } from "react"
import { useStaticQuery } from "gatsby"
import { get } from "lodash"
import { graphql } from "gatsby"

import { RichText } from "prismic-reactjs"
import { linkResolver } from "../utils/linkResolver"
import LogoImg from "../images/logo.svg"

const Footer = ({ navigation }) => {
  const data = useMemo(() => get(navigation, "data"), [navigation])
  const lang = useMemo(() => get(navigation, "lang"), [navigation])

  const footer_links = useMemo(() => get(data, "body1") || [], [data])
  const copy_text = useMemo(() => get(data, "copy_text.text") || [], [data])
  const current_year = new Date().getFullYear()

  const renderFooterLinks = (item, index) => {
    const text = get(item, "primary.title.text")
    const page = get(item, "primary.link")
    const linkUrl = linkResolver(page, lang)
    const link_target = linkUrl.includes("http")
      ? "_blank"
      : get(item, "link.target", "_self")

    return (
      <li key={index} className="p-medium">
        <a href={linkUrl} target={link_target} className="a-link">
          {text}
        </a>
      </li>
    )
  }

  const renderSocialLinks = (item, index) => {
    const text = get(item, "link_name[0].text")
    const link_url = get(item, "link_url.url", "#ola")
    const link_target = get(item, "link_url.target", "_blank")

    return (
      <li key={index} className="label label-medium">
        <a
          href={link_url}
          target={link_target}
          className="a-link"
          rel="noopener"
        >
          {text}
        </a>
      </li>
    )
  }

  const renderAddress = (item, index) => {
    const address = get(item, "address")

    return (
      <address className="p-small" key={index}>
        <RichText render={address} linkResolver={linkResolver} />
      </address>
    )
  }

  const haddleChangeLanguage = e => {
    // console.log('haddleChangeLanguage', e.target.value)

    const target = e.target.value
    if (target === currentLang) return null

    window.localStorage.userLanguage = target

    let target_path

    if (target === "en-gb") {
      pathname_split.splice(1, 1)
      target_path = pathname_split.map(element => element).join("/")
      // console.log('target_path', target_path)
      window.location.href = target_path
    }

    if (target === "pt-pt" || target === "zh-cn") {
      if (currentLang !== "en-gb") {
        pathname_split.splice(1, 1)
      }

      target_path =
        "/" + target + pathname_split.map(element => element).join("/")
      // console.log('target_path', target_path)
      window.location.href = target_path
    }
  }

  return (
    <footer className="main_footer">
      <div className="row">
        <div className="col col-9">
          <div className="main_footer__logo">
            <a href="/">
              <img src={LogoImg} alt="" />
            </a>
          </div>
        </div>
        {/* <div className="col col-3 main_footer__lang">
          <div className="btn-dropdown-simple">
            <select
              dir="rtl"
              onChange={haddleChangeLanguage}
              defaultValue={lang}
            >
              <option value="en-gb">English</option>
              <option value="pt-pt">Português</option>
            </select>
          </div>
        </div> */}
      </div>

      <div className="row main_footer__links">
        <div className="col col-7">
          <ul>
            {footer_links &&
              footer_links.map((item, index) => renderFooterLinks(item, index))}
          </ul>

          <a
            className="main_footer__livro_logo"
            href="https://www.livroreclamacoes.pt/Inicio/"
            target="_blank"
          >
            <img src="/images/livro_reclamacoes.png" />
          </a>
        </div>

        <div className="col col-5">
          <div className="main_footer__contact">
            <h6 className="h7 main_footer__contact__title">Contacto</h6>
            <p>
              +351 969 367 564
              <br />
              info@keystoalgarve.com
            </p>
          </div>
        </div>
      </div>

      <div className="row main_footer__copy">
        <div className="col col-12">
          <p className="p-small">
            {copy_text.replace("{current_year}", current_year)}
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
