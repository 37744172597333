import React, { useMemo } from "react"
import { get } from "lodash"
import { graphql } from "gatsby"
import PropertyCard from "./PropertyCard"
import { linkResolver } from "../../utils/linkResolver"

const Highlights = ({ data, lang }) => {
  console.log("[Highlights.js] data", data)
  console.log("[Highlights.js] lang", lang)

  const title = useMemo(() => get(data, "primary.title1.text"), [data])
  const button_text = useMemo(
    () => get(data, "primary.button_text.text"),
    [data],
  )
  const button_link = useMemo(() => get(data, "primary.button_link"), [data])

  const button_link_url = linkResolver(button_link, lang)

  const cards = useMemo(() => get(data, "items"), [data])

  return (
    <section className="section">
      <header className="row section__header">
        <div className="col col-6">
          <h3>{title}</h3>
        </div>
        <div className="col col-6 section__right_col hidden_iphonePlus">
          <a href={button_link_url} className="btn-secondary-grey">
            {button_text}
          </a>
        </div>
      </header>

      <div className="section__container">
        <div className="row">
          <div className="col col-12">
            <div className="property_card__container_list">
              <div className="property_card__container_list__inner">
                {cards &&
                  cards?.map((card, index) => (
                    <PropertyCard
                      lang={lang}
                      data={card?.property_card.document}
                      index={index}
                      key={index}
                    />
                  ))}
              </div>
            </div>
          </div>
        </div>

        <div className="col col-6 section__right_col visible_iphonePlus">
          <a href={button_link} className="btn-secondary-grey">
            {button_text}
          </a>
        </div>
      </div>
    </section>
  )
}

export default Highlights

export const HighlightsQuery = graphql`
  fragment Highlights on PrismicPageDataBodyHighlights {
    id
    slice_type
    slice_label
    primary {
      title1 {
        text
      }
      button_text {
        text
      }
      button_link {
        url
        uid
        type
      }
    }
    items {
      property_card {
        uid
        url
        type
        document {
          ...PropertyCard
        }
      }
    }
  }
`
